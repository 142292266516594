import * as THREE from 'three'
import PathHelper from '../../services/utils/PathHelper'
import ContentItemTransformer from './common/ContentItemTransformer'

import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'

export default class FbxContentLoader {
  loadContent(contentItem, output, dataSource, onLoadProgress, onLoadFinished) {
    output.objects = output.objects || []
    output.animations = output.animations || []

    const loader = new FBXLoader()

    const fbxFileUrl = PathHelper.getFullUrl(dataSource.url, contentItem.url)
    const contentItemTransformer = new ContentItemTransformer(contentItem)

    loader.load(fbxFileUrl, function (content) {
      const children = content.children

      if (content.animations && content.animations.length > 0) {
        const mixer = new THREE.AnimationMixer(content)
        const action = mixer.clipAction(content.animations[0])

        output.animations.push(action)

        // add a dummy object to control the animation
        const animatorObject = new THREE.Object3D()
        animatorObject.mixer = mixer

        children.push(animatorObject)
      }

      output.objects = output.objects.concat(children)

      for (const child of children) {
        contentItemTransformer.apply(child)
      }

      onLoadFinished(undefined, output)
    })
  }
}
