import * as THREE from 'three'
import Stats from 'stats-js'
import OutlineManager from './OutlineManager'

import Equirectangular from '../services/loaders/Equirectangular'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'

import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass'
import { ColorCorrectionShader } from 'three/examples/jsm/shaders/ColorCorrectionShader.js'
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass'
import { FilmShader } from 'three/examples/jsm/shaders/FilmShader.js'
import { SepiaShader } from 'three/examples/jsm/shaders/SepiaShader.js'
import { HueSaturationShader } from 'three/examples/jsm/shaders/HueSaturationShader.js'
import {BokehPass} from 'three/examples/jsm/postprocessing/BokehPass'
import { BrightnessContrastShader } from 'three/examples/jsm/shaders/BrightnessContrastShader.js'
import { VignetteShader } from 'three/examples/jsm/shaders/VignetteShader.js'

import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'

import { CopyShader } from 'three/examples/jsm/shaders/CopyShader'
import { Vector2 } from '../model/definitions/CommonTypes'
export default class RenderManager {
  config
  shouldRender = false
  sceneManager
  outlineManager
  rendererDivRef
  saoPass
  sepiaPass
  brightnessContrastPass
  hueSaturationPass
  vignettePass
  filmPass

  constructor(config, sceneManager) {
    this.config = config
    this.sceneManager = sceneManager
    this.outlineManager = new OutlineManager(config)
  }


  componentDidMount(rendererDivRef) {
    this.rendererDivRef = rendererDivRef

    this.setupStats()
    this.setupRenderer(this.config)
  }


  componentWillUnmount() {
    delete this.stats
  }


  componentWillReceiveProps(nextConfig) {
    // for some heavier changes, it's better to perform a check first
    if (
      nextConfig.renderer.antialias !== this.config.renderer.antialias ||
      nextConfig.renderer.ssaaSamples !== this.config.renderer.ssaaSamples
    )
      this.setupRenderer(nextConfig)

    this.applyPostProcessorConfigs(nextConfig)

    this.stats.domElement.style.visibility = nextConfig.renderer.showStats ? 'visible' : 'hidden'

    this.outlineManager.componentWillReceiveProps(nextConfig)

    this.updateShouldRenderer(true)

    this.config = nextConfig
  }



  setupStats() {
    this.stats = new Stats()

    this.stats.domElement.style.position = 'absolute'
    this.stats.domElement.style.top = '0px'
    this.stats.domElement.style.visibility = this.config.renderer.showStats ? 'visible' : 'hidden'

    this.rendererDivRef.appendChild(this.stats.domElement)
  }


  setupRenderer(config) {
    if (this.renderer) this.rendererDivRef.removeChild(this.renderer.domElement)

    const rendererOptions = {
      antialias: config.renderer.antialias,
      preserveDrawingBuffer: true,
      alpha: true
    }

    this.renderer = new THREE.WebGLRenderer(rendererOptions)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setClearColor(0xffffff)
    this.renderer.setClearAlpha(0)

    Equirectangular.initialize(this.renderer)

    // TODO: find a possible solution for the rerendering on screen size.
    this.rendererDivRef.appendChild(this.renderer.domElement)

    this.composer = null

    this.shouldRender = true
  }


  getBaseSize() {
    return this.rendererDivRef.getBoundingClientRect().width
  }


  updateResolution(width, height) {
    this.renderer.setSize(width, height)

    this.shouldRender = true
    this.composer = null
  }


  getScreenshot() {
    return this.renderer.domElement.toDataURL('image/png')
  }


  hasRenderer() {
    return Boolean(this.renderer)
  }


  updateShouldRenderer(status) {
    // we're only interested if it becomes true
    this.shouldRender |= status
  }


  render(camera) {
    if (this.config.renderer.showStats) {
      this.stats.update()
      this.shouldRender = true
    }
    this.outlineManager.update(this)

    if (this.shouldRender) {
      // this.renderer.render(this.sceneManager.scene, camera);

      this.renderComposer(this.sceneManager.scene, camera)
    }

    this.shouldRender = false
  }


  renderComposer(scene, camera) {
    if (!this.composer) {

      const size = this.renderer.getSize(new THREE.Vector2())

      this.composer = new EffectComposer(this.renderer)
      this.composer.setSize(size.x, size.y)

      const renderPass = new RenderPass(scene, camera)
      this.composer.addPass(renderPass)

      this.ssaaRenderPass = new SSAARenderPass(scene, camera)
      this.ssaaRenderPass.renderToScreen = false
      this.composer.addPass(this.ssaaRenderPass)

      this.saoPass = new SAOPass(scene, camera, false, true)
      this.composer.addPass(this.saoPass)

      this.hueSaturationPass = new ShaderPass(HueSaturationShader)
      this.composer.addPass(this.hueSaturationPass)

      this.brightnessContrastPass = new ShaderPass(BrightnessContrastShader)
      this.composer.addPass(this.brightnessContrastPass)

      this.vignettePass = new ShaderPass(VignetteShader)
      this.composer.addPass(this.vignettePass)

      this.filmPass = new ShaderPass(FilmShader)
      this.composer.addPass(this.filmPass)

      this.sepiaPass = new ShaderPass(SepiaShader)
      this.composer.addPass(this.sepiaPass)

      this.digitalGlitch = new GlitchPass(64)
      this.composer.addPass(this.digitalGlitch)

      this.applyPostProcessorConfigs(this.config)

      this.outlineManager.initialize(this.renderer, this.composer)

      this.copyPass = new ShaderPass(CopyShader)
      this.copyPass.renderToScreen = true
      this.composer.addPass(this.copyPass)
    }

    this.outlineManager.buildColorOutlines(scene, camera)
    this.composer.render()
  }


  applyPostProcessorConfigs(nextConfig){

    if (this.composer) {

      this.ssaaRenderPass.enabled = nextConfig.renderer.antialias

      this.ssaaRenderPass.sampleLevel = this.config.renderer.ssaaSamples

      this.saoPass.enabled = nextConfig.renderer.ssao.enabled
      this.saoPass.params.saoIntensity = nextConfig.renderer.ssao.intensity * 0.001 //0.00009
      this.saoPass.params.saoScale = nextConfig.renderer.ssao.scale //0.8
      this.saoPass.params.saoKernelRadius = nextConfig.renderer.ssao.kernelRadius //10
      this.saoPass.params.saoMinResolution = nextConfig.renderer.ssao.minResolution
      this.saoPass.params.saoBlurRadius = nextConfig.renderer.ssao.blurRadius

      this.hueSaturationPass.uniforms.hue.value = nextConfig.renderer.hue
      this.hueSaturationPass.uniforms.saturation.value = nextConfig.renderer.saturation

      this.brightnessContrastPass.uniforms.brightness.value = nextConfig.renderer.brightness
      this.brightnessContrastPass.uniforms.contrast.value = nextConfig.renderer.contrast

      this.vignettePass.uniforms.offset.value = nextConfig.renderer.vignetteOffset
      this.vignettePass.uniforms.darkness.value = nextConfig.renderer.vignetteDarkness

      this.digitalGlitch.enabled = nextConfig.renderer.glitch
      this.digitalGlitch.goWild = true

      this.sepiaPass.enabled = nextConfig.renderer.sepia
      this.filmPass.enabled = nextConfig.renderer.film
    }
  }
}
