import * as THREE from 'three'
import { easing, tween } from 'popmotion'

export default class AnimationHelper {
  animationOpts
  defaultAnimationDuration
  animationCount = 0

  /**
   * * @param animationOpts Options for the animation, if desired. If null or empty, no animation will occur. Otherwise, the
   * options are expected to have the following fields:
   * - type: the animation type. Check https://popmotion.io/api/easing/. Possibilities are 'linear', 'easeIn', 'easeOut', 'easeInOut','circIn', 'circOut', 'circInOut', 'backIn', 'backOut', 'backInOut', 'anticipate'
   * - duration: the duration of the animation. Default is set in the camera configuration, under "animationDuration".
   * - onFinish: a callback for moment when the animation completes.
   * @param defaultAnimationDuration Default time for animation, if not defined in the animationOptions
   */
  constructor(animationOpts, defaultAnimationDuration) {
    this.animationOpts = animationOpts

    // if the animation object has defined a onFinish function
    // simply passing it on to the setCameraPosition and setCameraTarget
    // will call it twice. We need to make sure that it only gets called
    // once, at the end of the two.
    if (animationOpts && animationOpts.onFinish) {
      const onFinishFunction = animationOpts.onFinish
      this.animationOpts = Object.assign({}, animationOpts, {
        onFinish: () => {
          if (--this.animationCount === 0) onFinishFunction()
        }
      })
    }

    this.defaultAnimationDuration = defaultAnimationDuration
  }

  animate(onUpdate) {
    this.animateCommon(0.0, 1.0, onUpdate, THREE.MathUtils.lerp)
  }

  animateNumeric(oldValue, newValue, onUpdate) {
    this.animateCommon(oldValue, newValue, onUpdate, THREE.MathUtils.lerp)
  }

  animateVector(oldValue, newValue, onUpdate) {
    const action = (val) => {
      oldValue.copy(val)
      if (onUpdate) onUpdate(val)
    }

    this.animateCommon(
      oldValue.clone(),
      newValue,
      action,
      (v1, v2, alpha) =>
        new THREE.Vector3(
          v1.x + (v2.x - v1.x) * alpha,
          v1.y + (v2.y - v1.y) * alpha,
          v1.z + (v2.z - v1.z) * alpha
        )
    )
  }

  animateColor(oldValue, newValue, onUpdate) {
    const action = (val) => {
      oldValue.copy(val)
      if (onUpdate) onUpdate(val)
    }

    this.animateCommon(oldValue.clone(), newValue, action, (c1, c2, percentage) =>
      c1.clone().lerp(c2, percentage)
    )
  }

  /**
   * Common function to animate a numeric value.
   * @param oldValue
   * @param newValue
   * @param onUpdate (Optional) Action to be called on value update. The new, interpolated value is passed as argument.
   * @param lerpFunction The lerp function, specific to the data type being passed
   */
  animateCommon(oldValue, newValue, onUpdate, lerpFunction) {
    this.animationCount++

    const animationOpts = this.animationOpts

    const action = (val) => {
      if (onUpdate) onUpdate(val)
    }

    if (animationOpts) {
      const animationType = animationOpts.type
        ? easing[animationOpts.type] || easing.easeInOut
        : easing.easeInOut
      const animationDuration =
        animationOpts.duration !== null && animationOpts.duration !== undefined
          ? animationOpts.duration
          : this.defaultAnimationDuration

      if (animationOpts.percentage !== null && animationOpts.percentage !== undefined) {
        const calculatedPercentage = animationType(animationOpts.percentage)
        newValue = lerpFunction(oldValue, newValue, calculatedPercentage)

        if (animationDuration === 0) {
          action(newValue)

          // use setTimeOut to make sure this does not get executed immediately,
          if (animationOpts.onFinish) setTimeout(() => animationOpts.onFinish(), 0)
        }
      }

      tween({
        from: oldValue,
        to: newValue,
        duration: animationDuration,
        ease: animationType
      }).start({
        update: (v) => action(v),
        complete: () => {
          if (animationOpts.onFinish) animationOpts.onFinish()
        }
      })
    } else {
      action(newValue)
    }
  }

  static newOrSelf(animation, animationDuration) {
    return animation instanceof AnimationHelper
      ? animation
      : new AnimationHelper(animation, animationDuration)
  }
}
