import * as THREE from 'three'

import { isEqual } from 'lodash'
import VectorHelper from '../services/utils/VectorHelper'
import Color from '../utils/Color'

const clipPlaneNormalsMap = {
  'X Up': { x: 1, y: 0, z: 0 },
  'X Down': { x: -1, y: 0, z: 0 },
  'Y Up': { x: 0, y: 1, z: 0 },
  'Y Down': { x: 0, y: -1, z: 0 },
  'Z Up': { x: 0, y: 0, z: 1 },
  'Z Down': { x: 0, y: 0, z: -1 }
}

export default class PlaneClippingManager {
  config
  renderManager
  nodeManager
  sceneManager


  constructor(config, renderManager, sceneManager, nodeManager) {
    this.config = config
    this.renderManager = renderManager
    this.nodeManager = nodeManager
    this.sceneManager = sceneManager

    this.sceneManager.onLoadFinishEvent.subscribe(this.onNodesLoadFinished, this)
  }


  componentDidMount() {
    this.renderManager.renderer.localClippingEnabled = this.config.renderer.clipPlaneEnabled

    if (
      this.config.renderer.clipPlaneNormal &&
      this.config.renderer.clipPlaneOffset !== null &&
      this.config.renderer.clipPlaneOffset !== undefined
    ) {
      // invert the normal, since it makes more sense to see the plane normal as the face we're looking at
      const normal = VectorHelper.convertToVector3(
        this.getVectorValue(this.config.renderer.clipPlaneNormal)
      ).multiplyScalar(-1)

      const constant = this.config.renderer.clipPlaneOffset
      this.plane = new THREE.Plane(normal, constant)
    }
  }


  componentWillReceiveProps(nextConfig) {
    this.renderManager.renderer.localClippingEnabled = nextConfig.renderer.clipPlaneEnabled

    let planeChanged = false
    if (
      !isEqual(this.config.renderer.clipPlaneNormal, nextConfig.renderer.clipPlaneNormal) ||
      this.config.renderer.clipPlaneOffset !== nextConfig.renderer.clipPlaneOffset
    ) {
      const constant = nextConfig.renderer.clipPlaneOffset
      const normal = VectorHelper.convertToVector3(
        this.getVectorValue(nextConfig.renderer.clipPlaneNormal)
      ).multiplyScalar(-1)
      this.plane = new THREE.Plane(normal, constant)

      planeChanged = true
    }

    if (
      nextConfig.renderer
        .clipPlaneEnabled /* && this.config.renderer.clipSectionColor !== nextConfig.renderer.clipSectionColor */
    ) {
      this.setupSceneObjects(nextConfig.renderer.clipSectionColor)
    }

    if (
      planeChanged ||
      nextConfig.renderer.clipPlaneEnabled !== this.config.renderer.clipPlaneEnabled) {

      this.reevaluateObjectsBehindPlane(nextConfig.renderer.clipPlaneEnabled)
    }

    this.config = nextConfig
  }


  reevaluateObjectsBehindPlane(clipPlaneEnabled) {
    for (const node of this.nodeManager.getNodes()) {
      node.object3d.visible = node.isIsFrontOfPlane(this.plane) || !clipPlaneEnabled
    }
  }


  onNodesLoadFinished() {
    if (this.config.renderer.clipPlaneEnabled) {
      this.setupSceneObjects(this.config.renderer.clipSectionColor)
      this.reevaluateObjectsBehindPlane(this.config.renderer.clipPlaneEnabled)
    }
  }


  setupSceneObjects(clipSectionColor) {
    clipSectionColor = clipSectionColor ? new Color(clipSectionColor).getThreeColor() : null

    for (const child of this.sceneManager.getSceneObjects()) {
      // affects meshes, but not pickingboxes and such
      if (child instanceof THREE.Mesh && !child.node) {
        // set up local clipping planes only
        child.material.side = THREE.FrontSide
        child.material.clippingPlanes = [this.plane]
        child.material.clipIntersection = true

        // create (or update) a copy of the mesh with inverted face rendering
        // just so as to give the effect of the cap
        if (child.clippingMesh) {
          if (clipSectionColor) child.clippingMesh.material.color = clipSectionColor
          else {
            child.remove(child.clippingMesh)
            delete child.clippingMesh
          }
        } else if (clipSectionColor) {
          const material = new THREE.MeshBasicMaterial({
            color: clipSectionColor,
            side: THREE.BackSide
          })
          material.clippingPlanes = [this.plane]
          material.clipIntersection = true
          material.polygonOffset = true
          material.polygonOffsetFactor = 1
          // material.polygonOffsetUnits = -1;

          child.clippingMesh = new THREE.Mesh(child.geometry, material)
          child.add(child.clippingMesh)
        }
      }
    }
  }


  componentWillUnmount() {
    this.sceneManager.onLoadFinishEvent.unsubscribeAll(this)
  }


  getVectorValue(stringOrVector) {
    if (typeof stringOrVector === 'string') {
      return clipPlaneNormalsMap[stringOrVector]
    }

    return stringOrVector
  }
}
