import * as THREE from 'three'
import PathHelper from '../../services/utils/PathHelper'
import ContentItemTransformer from './common/ContentItemTransformer'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

export default class GltfContentLoader {
  loadContent(contentItem, output, dataSource, onLoadProgress, onLoadFinished) {
    output.objects = output.objects || []
    output.animations = output.animations || []

    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath( './draco/' );

    const loader = new GLTFLoader()
    loader.setDRACOLoader(dracoLoader)

    const gltfFileUrl = PathHelper.getFullUrl(dataSource.url, contentItem.url)
    const contentItemTransformer = new ContentItemTransformer(contentItem)

    loader.load(gltfFileUrl, function(content) {
      const mixer = new THREE.AnimationMixer(content.scene)

      for (const animation of content.animations) {
        const action = mixer.clipAction(animation)
        output.animations.push(action)
      }

      // add a dummy object to control the animation
      const animatorObject = new THREE.Object3D()
      animatorObject.mixer = mixer

      let children = content.scene.children
      if (children.length === 1 && children[0].type === 'Object3D') children = children[0].children

      children = children.concat([animatorObject])

      output.objects = output.objects.concat(children)

      for (const child of children) {
        contentItemTransformer.apply(child)
      }

      onLoadFinished(undefined, output)
    })
  }
}
